import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule, MatIconRegistry } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MAT_SNACK_BAR_DEFAULT_OPTIONS } from '@angular/material/snack-bar';
import { MatStepperModule } from '@angular/material/stepper';
import { MAT_TABS_CONFIG } from '@angular/material/tabs';
import { BrowserModule, DomSanitizer } from '@angular/platform-browser';
import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TagInputModule } from 'ngx-chips';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { NgxMaskModule, IConfig } from 'ngx-mask'
import { NgxSliderModule } from '@angular-slider/ngx-slider';

import { NgxUiLoaderConfig, NgxUiLoaderModule } from 'ngx-ui-loader';
import localFr from '@angular/common/locales/fr';
import { CommonModule, registerLocaleData } from '@angular/common';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthInterceptor } from './lib/helpers/auth.interceptor';
import { ErrorInterceptor } from './lib/helpers/error.interceptor';
import { LibModule } from './lib/lib.module';
import { GoogleAnalyticsService } from './lib/services/google-analytics.service';
import { SharedVariablesService } from './lib/services/shared-variables.service';
import { HomeComponent } from './pages/home/home.component';
import { HeaderComponent } from './partials/header/header.component';
import { CategoryItemComponent } from './partials/category-item/category-item.component';
import { CategoryComponent } from './pages/category/category.component';
import { ShopItemComponent } from './partials/shop-item/shop-item.component';
import { SearchComponent } from './pages/search/search.component';
import { FooterComponent } from './partials/footer/footer.component';
import { RegisterComponent } from './pages/register/register.component';

// Configure loader
const ngxUiLoaderConfig: NgxUiLoaderConfig = {
  bgsColor: '#515151',
  bgsOpacity: 0.5,
  bgsPosition: 'center-center',
  bgsSize: 90,
  bgsType: 'fading-circle',
  blur: 4,
  delay: 0,
  fgsColor: '#515151',
  fgsPosition: 'center-center',
  fgsSize: 90,
  fgsType: 'fading-circle',
  gap: 24,
  masterLoaderId: 'master',
  overlayBorderRadius: '0',
  overlayColor: 'rgba(255,255,255,0.5)',
  pbColor: '#515151',
  pbDirection: 'ltr',
  pbThickness: 4,
  hasProgressBar: false,
  text: '',
  textColor: '#515151',
  textPosition: 'center-center',
  maxTime: -1,
  minTime: 250
};

export const options: Partial<IConfig> | (() => Partial<IConfig>) = null;

// Register the used locale file on your own.
registerLocaleData(localFr);

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    HeaderComponent,
    CategoryItemComponent,
    CategoryComponent,
    ShopItemComponent,
    SearchComponent,
    FooterComponent,
    RegisterComponent
  ],
  imports: [
    HttpClientModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    InfiniteScrollModule,
    NgxUiLoaderModule.forRoot(ngxUiLoaderConfig),
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatChipsModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatIconModule,
    LibModule,
    MatCardModule,
    NgxMaskModule.forRoot(),
    MatStepperModule,
    TagInputModule,
    MatCheckboxModule,
    NgxSliderModule,
    MatRadioModule,
  ],
  providers: [
    {provide: LOCALE_ID, useValue: 'fr'},
    {provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true, deps: [SharedVariablesService]},
    {provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true},
    {provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: {duration: 2500}},
    {provide: MAT_TABS_CONFIG, useValue: {animationDuration: 0}},
    {provide: MatDialogRef, useValue: {}},
    {provide: MAT_DIALOG_DATA, useValue: {}},
    GoogleAnalyticsService,
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(private matIconRegistry: MatIconRegistry, private domSanitizer: DomSanitizer){
    matIconRegistry.addSvgIconSet(domSanitizer.bypassSecurityTrustResourceUrl('/assets/custom.svg'));
  }
}

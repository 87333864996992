import { Injectable } from '@angular/core';
import { Adapter } from '../interfaces/adapter.interface';
export class LineOfBusiness {
  code: string;
  organisationCount: number = 0;

  constructor(code: string, organisationCount: number) {
    this.code = code;
    this.organisationCount = organisationCount;
  }

  get name(): string {
    return LineOfBusiness.getLineOfBusinessName(this.code);
  }

  static getLineOfBusinessName(code): string {
    const names: any = {
      FASHION: 'Mode',
      WOMENFASHION: 'Mode & accessoires Femme',
      MENFASHION: 'Mode & accessoires Homme',
      CHILDRENFASHION: 'Mode & accessoires Enfant',
      SHOES: 'Chaussures',
      DECORATION_FLOWER: 'Décoration & Fleurs',
      HOUSE_DECORATION: 'Maison & décoration',
      FLOWER: 'Fleurs',
      FOOD: 'Alimentation',
      FAST_FOOD: 'Restauration rapide',
      GROCERY: 'Epicerie',
      BEAUTY_TREATMENT: 'Beauté & Soins',
      BOOKSTORE_CULTURE: 'Livres & Culture',
      STATIONERY_BOOKSTORE: 'Librairie & Papeterie',
      STATIONERY: 'Papeterie & écriture',
      LEISURE_MULTIMEDIA: 'Loisirs & multimédia',
      TOYS_CHILDCARE: 'Jouets & Puériculture',
      SPORT: 'Sport',
      WRITTEN_PRESS: 'Presse',
      TOBACCO: 'Tabac',
      OPTICAL: 'Optique',
      PHARMACY_DRUGSTORE: 'Pharmacie & Parapharmacie',
      CATERING_TRADES: 'Métiers de bouche',
      BARS_RESTAURANT: 'Restaurants, cafés & bars',
      SERVICES: 'Services',
      JEWELERY_WATCHES: 'Bijouterie & Horlogerie',
      HARDWARE_STORE: 'Droguerie & Quincaillerie',
      IT_TELEPHONY: 'Informatique & Téléphonie'
    };

    return names[code] ? names[code] : code;
  }
}

@Injectable({
  providedIn: 'root',
})
export class LineOfBusinessAdapter implements Adapter<LineOfBusiness> {

  adapt(item: any): LineOfBusiness {
    return new LineOfBusiness(item.id, item.organisationCount);
  }
}

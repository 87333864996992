import { Injectable } from '@angular/core';
import { SharedVariablesService } from './shared-variables.service';

declare let gtag: Function;

@Injectable({
  providedIn: 'root'
})
export class GoogleAnalyticsService {

  constructor(private sharedVariablesService: SharedVariablesService) { }

  /**
   * Emits an Google Analytics event.
   * @see https://developers.google.com/analytics/devguides/collection/gtagjs/events
   * @param action
   * @param category
   * @param label
   * @param value
   */
  public eventEmitter(
    action: string,
    category: string = null,
    label: string = null,
    value?: number) {

    if (this.sharedVariablesService.googleAnalyticsConsent && this.sharedVariablesService.env === 'PROD') {
      gtag('event', action, {
        event_category: category,
        event_label: label,
        value,
      });
    }
  }
}

import { Injectable } from '@angular/core';
import {Meta, Title} from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class MetaService {
  constructor(private titleService: Title,
              private meta: Meta) {}

  updateMetaInfo(description?: string, author?: string, keywords?: string) {
    if (description) { this.meta.updateTag({ name: 'description', content: description }); }
    if (author) { this.meta.updateTag({ name: 'author', content: author }); }
    if (keywords) { this.meta.updateTag({ name: 'keywords', content: keywords }); }
  }
}

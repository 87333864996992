import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { LineOfBusiness } from '../../lib/model/LineOfBusiness';
import { Service } from '../../lib/model/Service';
import { OrganisationService } from '../../lib/services/organisation.service';
import { SharedVariablesService } from '../../lib/services/shared-variables.service';
import { MetaService } from '../../lib/services/meta.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  @Input('iFrameMode') iFrameMode: boolean;
  public searchForm: FormGroup;
  public linesOfBusiness: LineOfBusiness[];
  public services: Service[];

  constructor(public sharedVariablesService: SharedVariablesService,
              private router: Router,
              private organisationService: OrganisationService,
              private metaService: MetaService) {
    this.searchForm = new FormGroup({
      'search': new FormControl(undefined, Validators.required),
      'line-of-business': new FormControl(this.sharedVariablesService.lineOfBusiness ? this.sharedVariablesService.lineOfBusiness : 'all'),
      'service': new FormControl(this.sharedVariablesService.service ? this.sharedVariablesService.service : 'all')
    });
  }

  ngOnInit() {
    this.organisationService.getLinesOfBusiness().toPromise().then((linesOfBusiness) => {
      this.linesOfBusiness = linesOfBusiness;
    });

    this.organisationService.getServices().toPromise().then((services) => {
      this.services = services;
    });
  }

  search() {
    this.searchForm.updateValueAndValidity();

    const term = this.searchForm.get('search').value;
    const lineOfBusiness = this.searchForm.get('line-of-business').value !== 'all' ? this.searchForm.get('line-of-business').value : undefined;
    const service = this.searchForm.get('service').value !== 'all' ? this.searchForm.get('service').value : undefined;

    if (term || (lineOfBusiness && lineOfBusiness !== 'all') || (service && service !== 'all')) {
      this.metaService.updateMetaInfo('Mon shopping ' + this.sharedVariablesService.config.TITLE_PREFIX + ' -' +
        (term ? ' ' + term + ' -' : '') +
        ((lineOfBusiness && lineOfBusiness !== 'all') ? ' ' + LineOfBusiness.getLineOfBusinessName(lineOfBusiness) + ' -' : '') +
        ((service && service !== 'all') ? ' ' + Service.getServiceName(service) + ' -' : '') +
        ' Il est si facile d\'acheter locale à ' + this.sharedVariablesService.config.TITLE_PREFIX);
    } else {
      this.metaService.updateMetaInfo(this.sharedVariablesService.config.DEFAULT_META_DESCRIPTION);
    }

    this.router.navigate(['search'], { queryParams: { term, lineOfBusiness, service }});
  }
}

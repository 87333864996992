import config from '../../../assets/config.json';

/**
 * Configuration du front Shops. Surcharger le fichier de configuration config.json dans le dossier custom pour changer les settings d'une instance.
 */
export class Config {
  public INSTANCE_ID: string;
  public TITLE: string = 'Mes commerces';
  public TITLE_PREFIX: string = 'Mes commerces';
  public GOOGLE_ANALYTICS_TRACKING_ID: string = 'G-1NVC63EKFV';
  public REGISTRATION_PITCH: string;
  public DEFAULT_META_DESCRIPTION = 'Il est si facile d\'acheter locale.';

  constructor() {
    Object.assign(this, config);
  }
}

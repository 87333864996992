import { EventEmitter, Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { take } from 'rxjs/operators';
import { Config } from '../model/Config';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class SharedVariablesService extends ApiService {
  private readonly _instance: string;
  public lineOfBusiness: string = 'all';
  public service: string = 'all';
  public config: Config = new Config();
  public googleAnalyticsConsent: boolean = false;
  public _env: string = 'PROD';

  constructor() {
    super();

    this._instance = this.config['INSTANCE_ID'];
    this.getEnv();
  }

  clear() {
  }

  get instance(): string {
    return this._instance;
  }

  /**
   * Klaro consent watcher
   * @param obj
   * @param name
   * @param data
   */
  update(obj, name, data) {
    this.googleAnalyticsConsent = obj.consents['google-analytics'];
  }

  get env(): string {
    return this._env;
  }

  set env(value: string) {
    this._env = value;
  }

  getEnv() {
    let currentURI: any = window.location.host;
    currentURI = currentURI.split('.');
    if (currentURI.includes('staging') || currentURI.includes('testing') || currentURI.includes('localhost:4200')) {
      this._env = undefined;
    } else {
      this._env = 'PROD';
    }
  }
}

import { EventEmitter, Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class ErrorService extends ApiService {
  public error: Subject<string>;

  constructor() {
    super();
    this.error = new Subject<string>();
  }

  notify(error: string) {
    this.error.next(error);
  }
}

import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from './api.service';

@Injectable()
export class UploadService extends ApiService {

  constructor(private http: HttpClient) {
    super();
  }

  /**
   * Upload a file (document / image).
   * @param file
   * @param width
   * @param height
   * @param checkRatio
   * @param resizeImage
   * @param preserveRatio
   * @param additionalParams
   */
  upload(file: File, width?: number,
         height?: number,
         checkRatio: boolean = false,
         resizeImage: boolean = false,
         preserveRatio: boolean = false,
         additionalParams?: any): Observable<any> {

    let params = this.getParams(additionalParams);
    if (width && height) {
      params = params.set('width', width.toString())
                     .set('height', height.toString());
    }
    if (checkRatio) {
      params = params.set('checkRatio', 'true');
    }
    if (resizeImage) {
      params = params.set('resizeImage', 'true');
    }
    if (preserveRatio) {
      params = params.set('preserveRatio', 'true');
    }

    const formData: FormData = new FormData();
    formData.append('file', file, file.name);
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'multipart/form-data');
    headers.append('Accept', 'application/json');
    return this.http.put(this.baseUri + 'media/', formData, {headers, params, reportProgress: true, observe: 'events'});
  }

  private getParams(filter: object = {}): HttpParams {
    let params = new HttpParams();

    Object.keys(filter).forEach((key: any) => {
      if (filter[key] !== '' && filter[key] !== null && typeof filter[key] !== 'object') {
        params = params.set(key, filter[key]);
      }

      if (typeof filter[key] === 'object') {
        filter[key].forEach(value => {
          if (value !== '' && value !== null) {
            params = params.append(key, value);
          }
        });
      }
    });
    return params;
  }
}

import { Injectable } from '@angular/core';
import { Adapter } from '../interfaces/adapter.interface';

export class OrganisationAddress {
  location?: GeoJson;
  addressLine1?: string;
  addressLine2?: string;
  addressLine3?: string;
  postal?: string;
  city?: string;
  country?: string;

  constructor(location?: GeoJson, addressLine1?: string, addressLine2?: string, addressLine3?: string, postal?: string,
              city?: string, country?: string) {
    this.location = location;
    this.addressLine1 = addressLine1;
    this.addressLine2 = addressLine2;
    this.addressLine3 = addressLine3;
    this.postal = postal;
    this.city = city;
    this.country = country;
  }
}

@Injectable({
  providedIn: 'root',
})
export class OrganisationAddressAdapter implements Adapter<OrganisationAddress> {

  adapt(item: any): OrganisationAddress {
    const geoJsonAdapter: GeoJsonAdapter = new GeoJsonAdapter();

    return new OrganisationAddress(
      item.location ? geoJsonAdapter.adapt(item.location) : undefined,
      item.addressLine1,
      item.addressLine2,
      item.addressLine3,
      item.postal,
      item.city,
      item.country
    );
  }

  prepare(object: OrganisationAddress): any {
    const geoJsonAdapter: GeoJsonAdapter = new GeoJsonAdapter();

    const target = {};
    Object.assign(target, object);
    if (object && object.location) {
      target['location'] = geoJsonAdapter.prepare(object.location);
    }

    if (target['location'] === '') {
      delete  target['location'];
    }

    return target;
  }
}

class GeoJson {
  latitude: string;
  longitude: string;

  constructor(latitude?: string, longitude?: string) {
    this.latitude = latitude;
    this.longitude = longitude;
  }
}

@Injectable({
  providedIn: 'root',
})
export class GeoJsonAdapter implements Adapter<GeoJson> {

  adapt(item: any): GeoJson {
    const geoLoc = {lat: '', lgt: ''};
    const loc = item.split(',');
    geoLoc.lat = loc[0];
    geoLoc.lgt = loc[1];

    return new GeoJson(
      geoLoc.lat,
      geoLoc.lgt
    );
  }

  prepare(object: GeoJson): any {
    let target = '';
    Object.assign(target, object);
    if (object && object.latitude && object.longitude) {
      target = object.latitude + ',' + object.longitude;
    }
    return target;
  }
}

import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { LineOfBusiness, LineOfBusinessAdapter } from '../model/LineOfBusiness';
import { Organisation, OrganisationAdapter } from '../model/Organisation';
import { ServiceAdapter, Service } from '../model/Service';
import { ApiService } from './api.service';

@Injectable()
export class OrganisationService extends ApiService {
  constructor(private http: HttpClient,
              private adapter: OrganisationAdapter,
              private lineOfBusinessAdapter: LineOfBusinessAdapter,
              private serviceAdapter: ServiceAdapter) {
    super();
  }

  getOrganisation(organisationId: string): Observable<Organisation> {
    const params = new HttpParams();
    return this.http.get<Organisation>(this.baseUri + 'organisations/' + organisationId, {params: params}).pipe(
      map(item => this.adapter.adapt(item)));
  }

  getOrganisationList(type: string = 'EXTERNAL', lineOfBusiness?: string, service?: string, searchValue?: string) {
    let params = new HttpParams();
    params = params.set('types', 'EXTERNAL')
                   .set('excludeInactive', 'true');

    if (lineOfBusiness) {
      params = params.set('linesOfBusiness', lineOfBusiness);
    }
    if (service) {
      params = params.set('services', service);
    }
    if (searchValue) {
      params = params.set('searchValue', searchValue);
    }
    return this.http.get<Organisation[]>(this.baseUri + 'organisations', {params: params}).pipe(
      map((data: any[]) => data.map(item => this.adapter.adapt(item)))
    );
  }

  getLinesOfBusiness(organisationCount: boolean = true) {
    let params = new HttpParams();
    params = params.set('organisationCount', organisationCount.toString());

    return this.http.get<LineOfBusiness[]>(this.baseUri + 'organisations/linesOfBusiness', {params: params}).pipe(
      map((data: any[]) => data.map(item => this.lineOfBusinessAdapter.adapt(item)))
    );
  }

  getServices(organisationCount: boolean = true) {
    let params = new HttpParams();
    params = params.set('organisationCount', organisationCount.toString());

    return this.http.get<Service[]>(this.baseUri + 'organisations/services', {params: params}).pipe(
      map((data: any[]) => data.map(item => this.serviceAdapter.adapt(item)))
    );
  }

  searchNearestOrganisations(saleChannelCode: string, types: string[], address: string, limit: number = 2) {
    let params = new HttpParams();

    params = params.set('saleChannels', saleChannelCode)
                   .set('limit', limit.toString())
                   .set('excludeInactive', 'true');

    if (types.length > 0) {
      params = params.set('types', types.toString());
    }
    if (address) {
      params = params.set('address', address);
    }

    return this.http.get<Organisation[]>(this.baseUri + 'organisations/nearest/' + encodeURIComponent(address), {params: params}).pipe(
      map((data: any[]) => data.map(item => this.adapter.adapt(item)))
    );
  }

  searchOrganisations(saleChannelCode: string, types: string[], postalCode = undefined, latitude: number, longitude: number, limit: number = 2) {
    let params = new HttpParams();

    params = params.set('saleChannels', saleChannelCode)
                   .set('limit', limit.toString())
                   .set('excludeInactive', 'true');

    if (types.length > 0) {
      params = params.set('types', types.toString());
    }
    if (postalCode) {
      params = params.set('postalCode', postalCode);
    }
    if (latitude && longitude) {
      params = params.set('locationY', latitude.toString());
      params = params.set('locationX', longitude.toString());
    }

    return this.http.get<Organisation[]>(this.baseUri + 'organisations/search', {params: params}).pipe(
      map((data: any[]) => data.map(item => this.adapter.adapt(item)))
    );
  }
}

import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { ActivatedRoute, Router} from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import errorMessages from '../../../assets/error-messages.json';
import { ErrorService } from '../services/error.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

  constructor(private errorService: ErrorService,
              private router: Router,
              private route: ActivatedRoute,
              private ngxService: NgxUiLoaderService) {
  }

  static getVerboseMessage(code: string): string {
    return errorMessages[code] ? errorMessages[code] : code;
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const self = this;

    return next.handle(request).pipe(catchError(err => {
      // Stop loaders if running
      const loaders = self.ngxService.getLoaders();
      Object.keys(loaders).forEach((key) => {
        self.ngxService.stopLoader(key);
      });

      let textualError;
      textualError = err.error ? (err.error.code ? err.error.code : (err.error.error ? err.error.error : err.error.message)) : (err.message ? err.message : err.statusText);

      switch (err.status) {
        case 405:
          textualError = 'METHOD_NOT_ALLOWED';
          break;
        case 404:
          textualError = textualError ? textualError : 'PAGE_NOT_FOUND';
          break;
        case 403:
          textualError = 'ACCESS_FORBIDDEN';
          break;
        case 401:
          textualError = textualError ? textualError : 'ACCESS_UNAUTHORIZED';
          break;
        case 504:
          textualError = 'GATEWAY_TIMEOUT';
          break;
        default:
          textualError = err.error ? (err.error.code ? err.error.code : err.error.message) : (err.message ? err.message : err.statusText);
      }

      if (textualError) {
        self.errorService.notify(ErrorInterceptor.getVerboseMessage(textualError));
      }

      return throwError(err);
    }));
  }
}

import { Injectable } from '@angular/core';
import { Adapter } from '../interfaces/adapter.interface';
import { DayOfWeek } from './DayOfWeek'
import { OpeningHour, OpeningHourAdapter } from './OpeningHour';
import { OrganisationAddress, OrganisationAddressAdapter } from './OrganisationAddress';
import { OrganisationImplantationProperties, OrganisationImplantationPropertiesAdapter } from './OrganisationImplantationProperties';

export interface GroupedOpeningHours {
  [day: string]: OpeningDuration[];
}

export class Organisation {
  id: string;
  name: string;
  nickname?: string;

  currency?: string;
  type?: string;
  active?: boolean = true;

  locale?: any; // TODO type of locale
  zoneId?: any; // TODO type of locale

  department?: string;

  phone?: string;
  email?: string;

  address?: OrganisationAddress = undefined;
  logo?: string = undefined;

  openingHours?: OpeningHour[];
  openingHoursLabel: string;
  proposedServices: string[];

  legalForm?: string;
  capital?: string;
  registrationNumber?: string;
  intracommunityVAT?: string;

  excludeOrderPreparation: boolean;

  parentId?: string = undefined;

  properties: { [name: string]: string[] };

  children?: Organisation[] = undefined;
  parent: Organisation;

  nearest: string[];

  modificationDate: Date;

  implantationProperties: OrganisationImplantationProperties;

  level: any;

  appointmentHours: { day: string, openingDuration: OpeningDuration[] };
  distance: number = 0;
  linesOfBusiness: string[];
  services: string[];
  openedToday: string;
  imageUrl: string;
  appointmentUrl: string;
  informationUrl: string;
  eshopUrl: string;
  logoUrl: string;

  // Only used to easily edit or display openingHours in front - Do not send to WS
  groupedOpeningHours?: { day?: DayOfWeek, openingDuration?: OpeningDuration[] };

  constructor(id?: string, name?: string, nickname?: string, currency?: string, type?: string, active?: boolean, locale?: any, zoneId?: any,
              department?: string, phone?: string, email?: string, address?: OrganisationAddress, logo?: string, openingHours?: OpeningHour[],
              openingHoursLabel?: string, proposedServices?: string[], legalForm?: string, capital?: string, registrationNumber?: string,
              intracommunityVAT?: string, excludeOrderPreparation?: boolean, parentId?: string, properties?: { [p: string]: string[] },
              children?: Organisation[], parent?: Organisation, nearest?: string[], modificationDate?: Date,
              implantationProperties?: OrganisationImplantationProperties, level?: any,
              appointmentHours?: { day: string; openingDuration: OpeningDuration[]}, distance: number = 0, openedToday?: string, linesOfBusiness?: string[],
              services?: string[], imageUrl?: string, appointmentUrl?: string, informationUrl?: string, eshopUrl?: string, logoUrl?: string) {

    this.id = id;
    this.name = name;
    this.nickname = nickname;
    this.currency = currency;
    this.type = type;
    this.active = active;
    this.locale = locale;
    this.zoneId = zoneId;
    this.department = department;
    this.phone = phone;
    this.email = email;
    this.address = address;
    this.logo = logo;
    this.openingHours = openingHours;
    this.openingHoursLabel = openingHoursLabel;
    this.proposedServices = proposedServices;
    this.legalForm = legalForm;
    this.capital = capital;
    this.registrationNumber = registrationNumber;
    this.intracommunityVAT = intracommunityVAT;
    this.excludeOrderPreparation = excludeOrderPreparation;
    this.parentId = parentId;
    this.properties = properties;
    this.children = children;
    this.parent = parent;
    this.nearest = nearest;
    this.modificationDate = modificationDate;
    this.implantationProperties = implantationProperties;
    this.level = level;
    this.appointmentHours = appointmentHours;
    this.groupedOpeningHours = openingHours ? this.createGroupedOpeningHours(openingHours) : undefined;
    this.distance = distance;
    this.openedToday = openedToday;
    this.linesOfBusiness = linesOfBusiness;
    this.services = services;
    this.imageUrl = imageUrl;
    this.appointmentUrl = appointmentUrl;
    this.informationUrl = informationUrl;
    this.eshopUrl = eshopUrl;
    this.logoUrl = logoUrl;
  }

  /**
   * Return an object easier to edit and display opening hours
   * @param openingHours
   */
  createGroupedOpeningHours(openingHours: OpeningHour[]): GroupedOpeningHours {
    const tempGroupedOpeningHours: GroupedOpeningHours = {};
    openingHours.forEach((openingHour) => {
      if (!tempGroupedOpeningHours[openingHour.day]) {
        tempGroupedOpeningHours[DayOfWeek[openingHour.day]] = [];
        tempGroupedOpeningHours[DayOfWeek[openingHour.day]] = [new OpeningDuration(openingHour.from, openingHour.to)];
      } else {
        let newArray: OpeningDuration[];
        newArray = tempGroupedOpeningHours[DayOfWeek[openingHour.day]];
        newArray.push(new OpeningDuration(openingHour.from, openingHour.to));
        tempGroupedOpeningHours[DayOfWeek[openingHour.day]] = newArray;
      }
    });

    return tempGroupedOpeningHours;
  }
}

@Injectable({
  providedIn: 'root',
})
export class OrganisationAdapter implements Adapter<Organisation> {

  adapt(item: any): Organisation {

    const organisationAddressAdapter: OrganisationAddressAdapter = new OrganisationAddressAdapter();
    const openingHourAdapter: OpeningHourAdapter = new OpeningHourAdapter();
    const organisationAdapter: OrganisationAdapter = new OrganisationAdapter();
    const organisationImplantationPropertiesAdapter: OrganisationImplantationPropertiesAdapter = new OrganisationImplantationPropertiesAdapter();

    return new Organisation(
      item.id,
      item.name,
      item.nickname,
      item.currency,
      item.type,
      item.active,
      item.locale,
      item.zoneId,
      item.department,
      item.phone,
      item.email,
      item.address ? organisationAddressAdapter.adapt(item.address) : undefined,
      item.logo,
      item.openingHours ? item.openingHours.map((e) => {
        e = openingHourAdapter.adapt(e);
        return e;
      }) : undefined,
      item.openingHoursLabel,
      item.proposedServices,
      item.legalForm,
      item.capital,
      item.registrationNumber,
      item.intracommunityVAT,
      item.excludeOrderPreparation,
      item.parentId,
      item.properties,
      item.children ? item.children.map((e) => {
        e = organisationAdapter.adapt(e);
        return e;
      }) : undefined,
      item.parent ? organisationAdapter.adapt(item.parent) : undefined,
      item.nearest,
      item.modificationDate ? new Date(item.modificationDate.toString()) : undefined,
      item.implantationProperties ? organisationImplantationPropertiesAdapter.adapt(item.implantationProperties) : undefined,
      item.level,
      item.appointmentHours,
      item.distance,
      item.openedToday,
      item.linesOfBusiness,
      item.services,
      item.imageUrl,
      item.appointmentUrl,
      item.informationUrl,
      item.eshopUrl,
      item.logoUrl,
    );
  }

  prepare(object: Organisation): any {
    const organisationAddressAdapter: OrganisationAddressAdapter = new OrganisationAddressAdapter();

    const target = {};
    Object.assign(target, object);
    if (target['groupedOpeningHours']) {
      delete target['groupedOpeningHours'];
    }
    target['address'] = organisationAddressAdapter.prepare(object.address);

    return target;
  }
}

/**
 * @deprecated
 */
export enum OrganisationType {
  ROOT = 'ROOT', OUTLET = 'OUTLET', WEB = 'WEB', WAREHOUSE = 'WAREHOUSE', EXTERNAL = 'EXTERNAL',
  EXTERNAL_HEADQUARTER = 'EXTERNAL_HEADQUARTER', HEADQUARTER = 'HEADQUARTER', AGENCY = 'AGENCY',
}

export class OpeningDuration {
  from: string;
  to: string;

  constructor(from?: string, to?: string) {
    this.from = from;
    this.to = to;
  }
}

import { Component, Input, OnInit } from '@angular/core';
import { DayOfWeek } from '../../lib/model/DayOfWeek';
import { Organisation } from '../../lib/model/Organisation';
import { Service } from '../../lib/model/Service';
import { GoogleAnalyticsService } from '../../lib/services/google-analytics.service';

@Component({
  selector: 'app-shop-item',
  templateUrl: './shop-item.component.html',
  styleUrls: ['./shop-item.component.scss']
})
export class ShopItemComponent implements OnInit {
  @Input('organisation') organisation: Organisation;
  invisible: boolean = true;
  daysOfWeek: string[] = Object.keys(DayOfWeek);

  constructor(private googleAnalyticsService: GoogleAnalyticsService) { }

  ngOnInit() {
  }

  showInfos() {
    this.invisible = false;
    // Send analytics event
    this.googleAnalyticsService.eventEmitter('click', 'shop', this.organisation.name);
  }

  closeInfos() {
    this.invisible = true;
  }

  dayName(day): string {
    let days = {
      MONDAY: 'lundi',
      TUESDAY: 'mardi',
      WEDNESDAY: 'mercredi',
      THURSDAY: 'jeudi',
      FRIDAY: 'vendredi',
      SATURDAY: 'samedi',
      SUNDAY: 'dimanche',
    };

    return days[day];
  }

  isToday(day: string): boolean {
    let days = {
      MONDAY: 1,
      TUESDAY: 2,
      WEDNESDAY: 3,
      THURSDAY: 4,
      FRIDAY: 5,
      SATURDAY: 6,
      SUNDAY: 7,
    };

    return days[day] === (new Date()).getDay();
  }

  openUrl(url: string) {
    window.open(url, '_blank');
  }

  getServiceName(service: string) {
    return Service.getServiceName(service);
  }
}

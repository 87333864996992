import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router, RouterOutlet } from '@angular/router';
import { Subscription } from 'rxjs';
import { slideInAnimation } from './animations';
import { SharedVariablesService } from './lib/services/shared-variables.service';
import { klaroConfig } from '../assets/klaro-config.js';
import {MetaService} from './lib/services/meta.service';

declare const klaro: any;
declare var gtag: Function;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [
    slideInAnimation,
  ]
})
export class AppComponent implements OnInit {
  title = 'keyneosoft-shops';
  public klaroConsentManager: any;
  private klaroConfig: any = klaroConfig;
  public iFrameMode: boolean;
  private routeEventSubscription: Subscription;

  constructor(private titleService: Title,
              private location: Location,
              private sharedVariablesService: SharedVariablesService,
              public route: ActivatedRoute,
              private router: Router,
              private metaService: MetaService) {
    this.metaService.updateMetaInfo(this.sharedVariablesService.config.DEFAULT_META_DESCRIPTION);

    this.titleService.setTitle(this.sharedVariablesService.config.TITLE);

    this.routeEventSubscription = this.router.events.subscribe(event => {
      if (this.sharedVariablesService.config.GOOGLE_ANALYTICS_TRACKING_ID && this.sharedVariablesService.googleAnalyticsConsent) {
        if (event instanceof NavigationEnd) {
          gtag('config', this.sharedVariablesService.config.GOOGLE_ANALYTICS_TRACKING_ID,
            {
              'page_path': event.urlAfterRedirects
            }
          );
        }
      }
    });
  }

  ngOnInit(): void {
    if (this.location.path().indexOf('iframe') >= 0) {
      this.iFrameMode = true;
    }

    // Klaro for GPDR management
    klaro.setup(window['klaroConfig']);
    this.klaroConsentManager = klaro.getManager(window['klaroConfig']);
    this.klaroConsentManager.watch( this.sharedVariablesService );
    // Set googleAnalyticsConsent based on klaro states value
    if (this.klaroConsentManager && this.klaroConsentManager.states && this.klaroConsentManager.states['google-analytics']) {
      this.sharedVariablesService.googleAnalyticsConsent = this.klaroConsentManager.states['google-analytics'];
    } else {
      this.sharedVariablesService.googleAnalyticsConsent = false;
    }
  }

  prepareRoute(outlet: RouterOutlet) {
    return outlet && outlet.activatedRouteData && outlet.activatedRouteData['animation'];
  }
}

import { Injectable } from '@angular/core';
import { Adapter } from '../interfaces/adapter.interface';

export class OrganisationImplantationProperties {
  shelvesImplantation: ShelfImplantation[];

  constructor(shelvesImplantation: ShelfImplantation[]) {
    this.shelvesImplantation = shelvesImplantation;
  }
}

@Injectable({
  providedIn: 'root',
})
export class OrganisationImplantationPropertiesAdapter implements Adapter<OrganisationImplantationProperties> {

  adapt(item: any): OrganisationImplantationProperties {
    const shelfImplantationAdapter: ShelfImplantationAdapter = new ShelfImplantationAdapter();

    return new OrganisationImplantationProperties(
      item.shelvesImplantation ? item.shelvesImplantation.map((e) => {
        e = shelfImplantationAdapter.adapt(e);
        return e;
      }) : undefined
    );
  }
}

class ShelfImplantation {
  shelfCode: string;
  implantationSize: number;

  constructor(shelfCode?: string, implantationSize?: number) {
    this.shelfCode = shelfCode;
    this.implantationSize = implantationSize;
  }
}

@Injectable({
  providedIn: 'root',
})
export class ShelfImplantationAdapter implements Adapter<ShelfImplantation> {

  adapt(item: any): ShelfImplantation {

    return new ShelfImplantation(
      item.shelfCode,
      item.implantationSize
    );
  }
}

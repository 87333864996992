import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { LineOfBusiness } from '../../lib/model/LineOfBusiness';
import { OpeningHour } from '../../lib/model/OpeningHour';
import { Service } from '../../lib/model/Service';
import { ShopPortalRegistrationRequest } from '../../lib/model/ShopPortalRegistrationRequest';
import { OrganisationService } from '../../lib/services/organisation.service';
import { Options, LabelType } from '@angular-slider/ngx-slider';
import { ShopPortalService } from '../../lib/services/shopPortal.service';
import { UploadService } from '../../lib/services/upload.service';
import {MatOptionSelectionChange, MatSelectChange} from '@angular/material';


export class OpeningHourSliderSlot {
  from: number;
  to: number;

  constructor(from: number, to: number) {
    this.from = from;
    this.to = to;
  }
}

export interface OpeningHoursSlots {
  [day: string]: OpeningHoursSlot;
}

export interface OpeningHoursSlot {
  active: boolean;
  openingHourSliderSlot: OpeningHourSliderSlot[];
}

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {
  public linesOfBusiness: LineOfBusiness[];
  public services: Service[];
  days: string[] = ['MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY', 'SUNDAY'];
  jours: string[] = ['LUNDI', 'MARDI', 'MERCREDI', 'JEUDI', 'VENDREDI', 'SAMEDI', 'DIMANCHE'];
  secondFormGroup: FormGroup;
  thirdFormGroup: FormGroup;
  fourthFormValid: boolean = false;
  fifthFormGroup: FormGroup;
  openingHoursSlots: OpeningHoursSlots = {};
  formCompleted: boolean = false;
  openingHoursSliderOptions: Options;
  logoFileError: boolean = false;
  imageFileError: boolean = false;

  urlRegex: RegExp = /^(http[s]?:\/\/)(www.){0,1}[a-zA-Z0-9.-]+.[a-zA-Z]{2,5}[.]{0,1}/;
  phoneRegex: RegExp = /^0\d{9}$/;
  emailRegex: RegExp = /^((?!\.)[\w-_.]*[\w])(@\w+)(\.\w+(\.\w+)?[^.\W])$/;
  displayServicesOptionHint: boolean = false;

  constructor(private organisationService: OrganisationService,
              private ngxLoaderService: NgxUiLoaderService,
              private shopPortalService: ShopPortalService,
              private uploadService: UploadService) {

    this.openingHoursSliderOptions = {
      floor: 0,
      ceil: 1439,
      step: 5,
      hideLimitLabels: true,
      translate: (value: number, label: LabelType): string => {
        switch (label) {
          case LabelType.Low:
            return '<b>Ouverture : </b> ' + this.formatHour(value);
          case LabelType.High:
            return '<b>Fermeture : </b> ' + this.formatHour(value);
          default:
            return this.formatHour(value);
        }
      },
    };

    this.initOpeningHours();

    this.secondFormGroup = new FormGroup({
      name: new FormControl(undefined, Validators.required),
      phone: new FormControl(undefined, Validators.pattern(this.phoneRegex)),
      addressLine1: new FormControl(undefined, Validators.required),
      addressLine2: new FormControl(undefined),
      postal: new FormControl('68100', Validators.required),
      email: new FormControl(undefined, [Validators.required, Validators.pattern(this.emailRegex)]),
    });
    this.thirdFormGroup = new FormGroup({
      imageFile: new FormControl(undefined),
      logoFile: new FormControl(undefined),
      imageUrl: new FormControl(undefined, Validators.required),
      logoUrl: new FormControl(undefined, Validators.required),
      linesOfBusiness: new FormControl([], Validators.required),
      keywords: new FormControl([], Validators.required),
      // informationUrl: new FormControl(undefined, Validators.pattern(urlRegex)),
      appointmentUrl: new FormControl(undefined, Validators.pattern(this.urlRegex)),
      eshopUrl: new FormControl(undefined, [Validators.pattern(this.urlRegex)]),
      services: new FormControl([]),
    });
    this.fifthFormGroup = new FormGroup({
      meeting: new FormControl(undefined, Validators.required),
      duration: new FormControl('PT30M'),
      maxEventsCount: new FormControl(undefined),
    });
  }

  ngOnInit() {
    this.organisationService.getLinesOfBusiness(false).toPromise().then((linesOfBusiness) => {
      this.linesOfBusiness = linesOfBusiness;
    });

    this.organisationService.getServices(false).toPromise().then((services) => {
      this.services = services;
    });

    this.fifthFormGroup.get('meeting').valueChanges.subscribe(
    value => {
      if (value === 'true') {
        this.fifthFormGroup.get('duration').setValidators(Validators.required);
        this.fifthFormGroup.get('maxEventsCount').setValidators([Validators.required, Validators.pattern(/^[1-9]\d*$/)]);
        this.fifthFormGroup.updateValueAndValidity();
      } else {
        this.fifthFormGroup.get('duration').clearValidators();
        this.fifthFormGroup.get('maxEventsCount').clearValidators();
        this.fifthFormGroup.get('maxEventsCount').updateValueAndValidity();
        this.fifthFormGroup.updateValueAndValidity();
      }
    });
  }

  filledAppointmentUrl() {
    if (this.thirdFormGroup.get('appointmentUrl').value && this.thirdFormGroup.get('appointmentUrl').valid) {
      const res = ['MEET'].concat(this.thirdFormGroup.get('services').value);
      this.thirdFormGroup.get('services').setValue(res);
    } else if (this.thirdFormGroup.get('services').value.length > 0) {
      const indexOfMeet = this.thirdFormGroup.get('services').value.indexOf('MEET');
      if (indexOfMeet >= 0) {
        const servicesCopy = this.thirdFormGroup.get('services').value;
        servicesCopy.splice(indexOfMeet, 1);
        this.thirdFormGroup.get('services').setValue(servicesCopy);
      }
    }
  }

  initOpeningHours() {
    this.openingHoursSlots = {};
    this.days.forEach((day) => {
      this.openingHoursSlots[day] = { active: false, openingHourSliderSlot: [] };
    });
  }

  activeOpeningDayClicked(day: string) {
    if (this.openingHoursSlots[day].openingHourSliderSlot.length === 0) {
      // Create the first opening hour slot
      this.openingHoursSlots[day].openingHourSliderSlot.push(new OpeningHourSliderSlot(540, 1200));
    }
    this.openingHoursSlots[day].active = !this.openingHoursSlots[day].active;

    this.fourthFormValid = false;
    this.days.forEach((day) => {
      if (this.openingHoursSlots[day].active) {
        this.fourthFormValid = true;
      }
    });
  }

  formatHour(value: number | null, withSeconds: boolean = false) {
    if (value) {
      let result = Math.floor(value / 60).toString().padStart(2, '0') + ':' + (value % 60).toString().padStart(2, '0');
      if (withSeconds) {
        result = result + ':00';
      }
      return result;
    } else {
      return withSeconds ? '00:00:00' : '00:00';
    }
  }

  addEditableOpeningHourSlot(day: string) {
    if (this.openingHoursSlots[day].openingHourSliderSlot.length > 0) {
      const lastOpenHoursSliderSlot: OpeningHourSliderSlot = this.openingHoursSlots[day].openingHourSliderSlot[this.openingHoursSlots[day].openingHourSliderSlot.length - 1];
      this.openingHoursSlots[day].openingHourSliderSlot.push(new OpeningHourSliderSlot(lastOpenHoursSliderSlot.to + 60, lastOpenHoursSliderSlot.to + 3 * 60));
    } else {
      this.openingHoursSlots[day].openingHourSliderSlot = [new OpeningHourSliderSlot(540, 1200)];
    }
  }

  removeEditableOpeningHourSlot(day: string, index: number) {
    this.openingHoursSlots[day].openingHourSliderSlot.splice(index, 1);
    if (this.openingHoursSlots[day].openingHourSliderSlot.length === 0) {
      this.openingHoursSlots[day].active = false;
    }
  }

  uploadLogoFile($event) {
    this.logoFileError = false;
    const file = $event.target.files[0];

    if (!file || file.type !== 'image/png' || file.size > 2000000) {
      this.logoFileError = true;
      this.thirdFormGroup.get('logoFile').reset();
    } else {
      this.ngxLoaderService.startBackgroundLoader('loader-logo');
      this.uploadService.upload(file, 180, 180, false, true, true).toPromise().then((res) => {
        this.ngxLoaderService.stopBackgroundLoader('loader-logo');
        if (res && res.body) {
          this.thirdFormGroup.get('logoUrl').setValue(res.body.url);
        }
      });
    }
  }

  uploadImageFile($event) {
    this.imageFileError = false;
    const file = $event.target.files[0];

    if (!file || file.type !== 'image/jpeg' || file.size > 2000000) {
      this.imageFileError = true;
      this.thirdFormGroup.get('imageFile').reset();
    } else {
      this.ngxLoaderService.startBackgroundLoader('loader-image');
      this.uploadService.upload(file, 740, 740, false, true, true).toPromise().then((res) => {
        this.ngxLoaderService.stopBackgroundLoader('loader-image');
        if (res && res.body) {
          this.thirdFormGroup.get('imageUrl').setValue(res.body.url);
        }
      })
    }
  }

  sendRequest() {
    if (this.secondFormGroup.valid && this.thirdFormGroup.valid && this.fourthFormValid && this.fifthFormGroup.valid) {
      // Prepare opening hours
      const openingHours: OpeningHour[] = [];

      this.days.forEach((day) => {
        if (this.openingHoursSlots[day].active && this.openingHoursSlots[day].openingHourSliderSlot.length > 0) {
          this.openingHoursSlots[day].openingHourSliderSlot.forEach((openingHour: OpeningHourSliderSlot) => {
            openingHours.push(new OpeningHour(day,
              this.formatHour(openingHour.from, true),
              this.formatHour(openingHour.to, true)));
          });
        }
      });

      // Create request object
      const shopPortalRegistrationRequest: ShopPortalRegistrationRequest = new ShopPortalRegistrationRequest(
        this.secondFormGroup.get('name').value,
        this.secondFormGroup.get('phone').value,
        this.secondFormGroup.get('email').value,
        this.secondFormGroup.get('addressLine1').value,
        this.secondFormGroup.get('addressLine2').value,
        this.secondFormGroup.get('postal').value,
        this.thirdFormGroup.get('imageUrl').value,
        this.thirdFormGroup.get('logoUrl').value,
        this.thirdFormGroup.get('linesOfBusiness').value,
        this.thirdFormGroup.get('keywords').value,
        this.thirdFormGroup.get('services').value,
        openingHours,
        this.fifthFormGroup.get('meeting').value == 'true',
        this.thirdFormGroup.get('appointmentUrl').value,
        this.thirdFormGroup.get('eshopUrl').value,
        undefined,
        this.fifthFormGroup.get('meeting').value == 'true' ? this.fifthFormGroup.get('duration').value : undefined,
        this.fifthFormGroup.get('meeting').value == 'true' ? this.fifthFormGroup.get('maxEventsCount').value : undefined,
      );

      this.shopPortalService.registrationRequest(shopPortalRegistrationRequest).toPromise().then((res) => {
        this.formCompleted = true;
      });
    }
  }

  eshopUrlChange(event: Event) {
    if (this.thirdFormGroup.get('eshopUrl').valid && this.thirdFormGroup.get('eshopUrl').value) {
      this.displayServicesOptionHint = true;
    } else {
      this.displayServicesOptionHint = false;
    }
  }

  servicesChange(event: MatSelectChange) {
    if (this.thirdFormGroup.get('services').value.indexOf('CNC') > -1  || this.thirdFormGroup.get('services').value.indexOf('DELIVERY') > -1) {
      this.thirdFormGroup.get('eshopUrl').setValidators([Validators.required, Validators.pattern(this.urlRegex)]);
      this.thirdFormGroup.get('eshopUrl').markAsTouched();
      this.thirdFormGroup.get('eshopUrl').updateValueAndValidity();
    } else {
      this.thirdFormGroup.get('eshopUrl').setValidators([Validators.pattern(this.urlRegex)]);
      this.thirdFormGroup.get('eshopUrl').updateValueAndValidity();
    }
  }
}

import { Component, Input, OnInit } from '@angular/core';
import { LineOfBusiness } from '../../lib/model/LineOfBusiness';
import { GoogleAnalyticsService } from '../../lib/services/google-analytics.service';
import { SharedVariablesService } from '../../lib/services/shared-variables.service';

@Component({
  selector: 'app-category-item',
  templateUrl: './category-item.component.html',
  styleUrls: ['./category-item.component.scss']
})
export class CategoryItemComponent implements OnInit {
  @Input('lineOfBusiness') lineOfBusiness: LineOfBusiness;

  constructor(private sharedVariablesService: SharedVariablesService,
              private googleAnalyticsService: GoogleAnalyticsService) { }

  ngOnInit() {
  }

  setLineOfBusiness() {
    this.sharedVariablesService.lineOfBusiness = this.lineOfBusiness.code;
    // Send analytics event
    this.googleAnalyticsService.eventEmitter('click', 'category', this.lineOfBusiness.name);
  }
}

import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  ShopPortalRegistrationRequest,
  ShopPortalRegistrationRequestAdapter
} from '../model/ShopPortalRegistrationRequest';
import { ApiService } from './api.service';
import { map } from 'rxjs/operators';

@Injectable()
export class ShopPortalService extends ApiService {
  constructor(private http: HttpClient,
              private shopPortalRegistrationRequestAdapter: ShopPortalRegistrationRequestAdapter) {
    super();
  }

  registrationRequest(shopPortalRegistrationRequest: ShopPortalRegistrationRequest): Observable<any> {
    const params = new HttpParams();

    return this.http.post<any>(this.baseUri + 'shopPortal/registrationRequest', shopPortalRegistrationRequest, {params});
  }
}

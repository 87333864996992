import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SharedVariablesService } from '../services/shared-variables.service';

@Injectable({
  providedIn: 'root',
})
export class AuthInterceptor implements HttpInterceptor {
  constructor(public sharedVariablesService: SharedVariablesService) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // if (this.sharedVariablesService && this.sharedVariablesService.organisation || this.sharedVariablesService.config.ROOT_ORGANISATION_ID) {
    //   let organisationId: string = this.sharedVariablesService.organisation ? this.sharedVariablesService.organisation.id : this.sharedVariablesService.config.ROOT_ORGANISATION_ID;
    //   // We will keep organisationId if defined in request
    //   if (request.headers.has('organisationId')) {
    //     organisationId = request.headers.get('organisationId');
    //   }
    //
    //   request = request.clone({
    //     setHeaders: {
    //       // Authorization: 'Bearer ' + currentUser.token,
    //       organisationId: organisationId,
    //     },
    //   });
    // }

    return next.handle(request);
  }
}

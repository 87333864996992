import { Injectable } from '@angular/core';
import { Adapter } from '../interfaces/adapter.interface';
import { OpeningHour } from './OpeningHour';
import { Organisation } from './Organisation';

export class ShopPortalRegistrationRequest {
  public organisationName: string;
  public organisationPhone: string;
  public organisationEmail: string;
  public organisationAddressLine1 : string;
  public organisationAddressLine2: string;
  public organisationAddressPostal: string;
  public organisationImageUrl: string;
  public organisationLogoUrl: string;
  public organisationLinesOfBusiness: string[];
  public organisationKeywords: string[];
  public organisationServices: string[];
  public organisationOpeningHours: OpeningHour[];
  public appointmentEnable : boolean = false;
  public organisationAppointmentUrl: string;
  public organisationEshopUrl: string;
  public organisationInformationUrl: string;
  public timeSlotInterval: string;
  public timeSlotMaxEventsCount: number;

  constructor(organisationName: string,
              organisationPhone: string,
              organisationEmail: string,
              organisationAddressLine1: string,
              organisationAddressLine2: string,
              organisationAddressPostal: string,
              organisationImageUrl: string,
              organisationLogoUrl: string,
              organisationLinesOfBusiness: string[],
              organisationKeywords: string[],
              organisationServices: string[],
              organisationOpeningHours: OpeningHour[],
              appointmentEnable: boolean,
              organisationAppointmentUrl: string,
              organisationEshopUrl: string,
              organisationInformationUrl: string,
              timeSlotInterval: string,
              timeSlotMaxEventsCount: number) {
    this.organisationName = organisationName;
    this.organisationPhone = organisationPhone;
    this.organisationEmail = organisationEmail;
    this.organisationAddressLine1 = organisationAddressLine1;
    this.organisationAddressLine2 = organisationAddressLine2;
    this.organisationAddressPostal = organisationAddressPostal;
    this.organisationImageUrl = organisationImageUrl;
    this.organisationLogoUrl = organisationLogoUrl;
    this.organisationLinesOfBusiness = organisationLinesOfBusiness;
    this.organisationKeywords = organisationKeywords;
    this.organisationServices = organisationServices;
    this.organisationOpeningHours = organisationOpeningHours;
    this.appointmentEnable = appointmentEnable;
    this.organisationAppointmentUrl = organisationAppointmentUrl;
    this.organisationEshopUrl = organisationEshopUrl;
    this.organisationInformationUrl = organisationInformationUrl;
    this.timeSlotInterval = timeSlotInterval;
    this.timeSlotMaxEventsCount = timeSlotMaxEventsCount;
  }
}


@Injectable({
  providedIn: 'root',
})
export class ShopPortalRegistrationRequestAdapter implements Adapter<ShopPortalRegistrationRequest> {

  adapt(item: any): ShopPortalRegistrationRequest {
    return new ShopPortalRegistrationRequest(
      item.organisationName,
      item.organisationPhone,
      item.organisationEmail,
      item.organisationAddressLine1,
      item.organisationAddressLine2,
      item.organisationAddressPostal,
      item.organisationImageUrl,
      item.organisationLogoUrl,
      item.organisationLinesOfBusiness,
      item.organisationKeywords,
      item.organisationServices,
      item.organisationOpeningHours,
      item.appointmentEnable,
      item.organisationAppointmentUrl,
      item.organisationEshopUrl,
      item.organisationInformationUrl,
      item.timeSlotInterval,
      item.timeSlotMaxEventsCount,
    )
  }
}

import { Component, OnDestroy, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Subscription } from 'rxjs';
import { LineOfBusiness } from '../../lib/model/LineOfBusiness';
import { Organisation } from '../../lib/model/Organisation';
import { Service } from '../../lib/model/Service';
import { OrganisationService } from '../../lib/services/organisation.service';
import { SharedVariablesService } from '../../lib/services/shared-variables.service';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit, OnDestroy {
  private queryParamsSubscription: Subscription;
  public organisations: Organisation[] = [];
  public searchTerm: string;
  public lineOfBusiness: string;
  public service: string;

  constructor(public route: ActivatedRoute,
              public sharedVariablesService: SharedVariablesService,
              private ngxLoaderService: NgxUiLoaderService,
              private organisationService: OrganisationService,
              private titleService: Title) { }

  ngOnInit() {
    // Search term if passed in GET parameters
    this.searchTerm = this.route.snapshot.queryParamMap.get('term');
    this.lineOfBusiness = this.route.snapshot.queryParamMap.get('lineOfBusiness');
    // Subscribe to changes for term GET parameter
    this.queryParamsSubscription = this.route.queryParamMap.subscribe((params) => {
      this.searchTerm = params.get('term');
      this.lineOfBusiness = params.get('lineOfBusiness');
      this.service = params.get('service');

      this.titleService.setTitle(this.sharedVariablesService.config.TITLE_PREFIX + ' - ' + this.searchTerm);

      // Do search
      this.searchOrganisations();
    });

    // Do search
    this.searchOrganisations();
  }

  ngOnDestroy() {
    this.queryParamsSubscription.unsubscribe();
  }

  searchOrganisations() {
    this.ngxLoaderService.start();
    this.organisationService.getOrganisationList('EXTERNAL', this.lineOfBusiness, this.service, this.searchTerm).toPromise()
      .then((organisations) => {
        this.ngxLoaderService.stop();
        this.organisations = organisations;
    });
  }

  goBack() {
    this.ngxLoaderService.stop();
    this.sharedVariablesService.lineOfBusiness = 'all';
    this.sharedVariablesService.service = 'all';
  }

  getLineOfBusinessName(lineOfBusiness: string): string {
    return LineOfBusiness.getLineOfBusinessName(lineOfBusiness);
  }

  getServiceName(service: string): string {
    return Service.getServiceName(service);
  }
}

import { Injectable } from '@angular/core';
import { Adapter } from '../interfaces/adapter.interface';

export class OpeningHour {
  day: string; // TODO use enum
  from: string;
  to: string;

  constructor(day?: string, from?: string, to?: string) {
    this.day = day;
    this.from = from;
    this.to = to;
  }
}

@Injectable({
  providedIn: 'root',
})
export class OpeningHourAdapter implements Adapter<OpeningHour> {

  adapt(item: any): OpeningHour {

    return new OpeningHour(
      item.day,
      item.from,
      item.to
    );
  }
}

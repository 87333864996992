import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CategoryComponent } from './pages/category/category.component';
import { HomeComponent } from './pages/home/home.component';
import { RegisterComponent } from './pages/register/register.component';
import { SearchComponent } from './pages/search/search.component';


const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
    data: {
      animation: true
    },
  },
  {
    path: 'iframe',
    component: HomeComponent,
    data: {animation: true},
  },
  {
    path: 'category/:code',
    component: CategoryComponent,
    data: {animation: false},
  },
  {
    path: 'search',
    component: SearchComponent,
    data: {animation: false},
  },
  {
    path: 'register',
    component: RegisterComponent,
    data: {animation: false},
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }

import { Component, OnDestroy, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { LineOfBusiness } from '../../lib/model/LineOfBusiness';
import { OrganisationService } from '../../lib/services/organisation.service';
import { SharedVariablesService } from '../../lib/services/shared-variables.service';
import {MetaService} from '../../lib/services/meta.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, OnDestroy {
  public linesOfBusiness: LineOfBusiness[];
  private showGoUpArrow: boolean = false;

  constructor(public sharedVariablesService: SharedVariablesService,
              private organisationService: OrganisationService,
              private titleService: Title,
              private metaService: MetaService) {
    this.metaService.updateMetaInfo(this.sharedVariablesService.config.DEFAULT_META_DESCRIPTION);
  }

  ngOnInit() {
    this.organisationService.getLinesOfBusiness().toPromise().then((linesOfBusiness) => {
      this.linesOfBusiness = linesOfBusiness;
    });
    window.addEventListener('scroll', this.scroll.bind(this), true);
    this.titleService.setTitle(this.sharedVariablesService.config.TITLE);
  }

  ngOnDestroy() {
    window.removeEventListener('scroll', this.scroll.bind(this), true);
  }

  /**
   * Go to the top of the page.
   */
  goUp() {
    window.scroll(0, 0);
  }

  /**
   * Scroll event.
   * @param $event
   */
  scroll($event) {
    this.showGoUpArrow = window.scrollY > 0;
  }
}

import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { PhoneFormatPipe } from './helpers/PhoneFormatPipe';
import { SortByPipe } from './helpers/sort-by.pipe';
import { OrganisationService } from './services/organisation.service';
import { SharedVariablesService } from './services/shared-variables.service';
import { ShopPortalService } from './services/shopPortal.service';
import { UploadService } from './services/upload.service';

@NgModule({
  declarations: [
    PhoneFormatPipe,
    SortByPipe,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    RouterModule,
  ],
  exports: [
    HttpClientModule,
    PhoneFormatPipe,
    SortByPipe,
  ],
  providers: [
    OrganisationService,
    UploadService,
    ShopPortalService,
  ],
})
export class LibModule {
}

import { Pipe, PipeTransform } from '@angular/core';

/**
 * Pipe to format price with superior €cents by inserting HTML.
 */
@Pipe({
  name: 'phoneformat',
})
export class PhoneFormatPipe implements PipeTransform {

  transform(value: string): string {
    const regex = /^(\d{2})(\s|\.|-|\/)?(\d{2})(\s|\.|-|\/)?(\d{2})(\s|\.|-|\/)?(\d{2})(\s|\.|-|\/)?(\d{2})$/;
    let matches = regex.exec(value);
    if (matches) {
      return matches[1] + ' ' + matches[3] + ' ' + matches[5] + ' ' + matches[7] + ' ' + matches[9];
    } else {
      return value;
    }
  }
}

import { Component, OnDestroy, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Subscription } from 'rxjs';
import { LineOfBusiness } from '../../lib/model/LineOfBusiness';
import { Organisation } from '../../lib/model/Organisation';
import { OrganisationService } from '../../lib/services/organisation.service';
import { SharedVariablesService } from '../../lib/services/shared-variables.service';
import { MetaService } from '../../lib/services/meta.service';

@Component({
  selector: 'app-category',
  templateUrl: './category.component.html',
  styleUrls: ['./category.component.scss']
})
export class CategoryComponent implements OnInit, OnDestroy {
  public organisations: Organisation[];
  public code: string;
  public categoryName: string;
  private paramsSubscription: Subscription;
  private showGoUpArrow: boolean = false;

  constructor(public route: ActivatedRoute,
              private sharedVariablesService: SharedVariablesService,
              private ngxLoaderService: NgxUiLoaderService,
              private organisationService: OrganisationService,
              private titleService: Title,
              private metaService: MetaService) { }

  ngOnInit() {
    // Subscribe to params changes
    const self = this;
    this.paramsSubscription = this.route.paramMap.subscribe((params) => {
      this.ngxLoaderService.start();
      // Category code in params
      self.code = params.has('code') ? params.get('code') : undefined;
      // Brand code in params
      if (self.code) {
        this.categoryName = LineOfBusiness.getLineOfBusinessName(self.code);
        this.metaService.updateMetaInfo('Mon shopping ' + this.sharedVariablesService.config.TITLE_PREFIX + ' - ' + this.categoryName
          + ' - Il est si facile d\'acheter locale à ' + this.sharedVariablesService.config.TITLE_PREFIX);
        this.titleService.setTitle(this.sharedVariablesService.config.TITLE_PREFIX + ' - ' + this.categoryName);
      }
      self.fetchOrganisations();
    });

    window.addEventListener('scroll', this.scroll.bind(this), true);
  }

  ngOnDestroy() {
    window.removeEventListener('scroll', this.scroll.bind(this), true);
  }

  private fetchOrganisations() {
    this.organisationService.getOrganisationList('EXTERNAL', this.code).toPromise().then((organisations) =>  {
      this.organisations = organisations;
      this.ngxLoaderService.stop();
    });
  }

  goBack() {
    this.ngxLoaderService.stop();
    this.sharedVariablesService.lineOfBusiness = 'all';
    this.sharedVariablesService.service = 'all';
  }

  /**
   * Go to the top of the page.
   */
  goUp() {
    window.scroll(0, 0);
  }

  /**
   * Scroll event.
   * @param $event
   */
  scroll($event) {
    this.showGoUpArrow = window.scrollY > 0;
  }
}
